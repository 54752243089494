import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/content.js";
import BassinBallon from 'src/components/images/bassin-ballons';
import Activite from 'src/components/images/activite';
import SEO from 'src/components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Pédagogie" description="Description de la pédagogie par les encadrants de l'association l'enfant d'eau" mdxType="SEO" />
    <h2>{`Pédagogie`}</h2>
    <p>{`L’activité bébé-nageur est un moment d’une grande richesse relationnelle où l’enfant peut exercer et développer sa créativité en construisant des liens de confiance. C’est pour la famille un temps de jeu, de parole et de rencontres. C’est une approche très ludique de l’eau avec son enfant.`}</p>
    <p>{`Le bébé n’est pas inactif, il observe, il écoute mais aussi, il tète pour se rassurer, mordille, goûte l'eau, crache, souffle, vocalise... Il aime être porté, sentir les caresses et l'enveloppement de l’eau. Il n’est donc pas nécessaire de précipiter les choses en lui proposant trop vite un support plus instable que nos bras.`}</p>
    <p>{`Les découvertes sont alors très riches sur le plan sensoriel ; dans cette situation, parents et enfants se retrouvent à la même hauteur, tête hors de l'eau. Les jeux de regards sont privilégiés, les rapports de proportion entre adultes et enfants sont réduits. Du portage aux premiers éloignements le milieu aquatique suscite un rapprochement corporel enfant-adulte. Dans cette situation de confort et de sécurité posturale, le bébé se détend, ses membres se déplient, s'allongent, ses mains s'ouvrent. Cette ouverture traduit un bien-être. À l'inverse, un comportement de fermeture, un agrippement excessif, traduisent un malaise, un déplaisir, une recherche de proximité plus grande avec l'adulte afin de se sécuriser à nouveau.`}</p>
    <p>{`C'est en l'autorisant à vivre assez longtemps des enveloppements rassurants et agréables que l'adulte permet à l'enfant, sécurisé, vitalisé, de s'éloigner. L'enfant jouera alors avec ses appuis plantaires, cherchera à se redresser, il sera nécessaire de lui laisser vivre la verticalité (le « plat ventre » est encore ici mal adapté).`}</p>
    <p>{`Progressivement les promenades dans le bassin (guidées par le regard et les mimiques de l'enfant) sont l'occasion de rencontrer d'autres personnes, de nombreux objets et jeux.`}</p>
    <Activite mdxType="Activite" />
    <p>{`Par la suite, les activités évoluent en fonction des envies et des capacités. Les enfants pourront notamment faire du « bateau », glisser sur les toboggans, sauter, nager sous l’eau, s’accrocher à des cordes, monter sur des tapis ou sur les rochers, effectuer des parcours sous-marins.`}</p>
    <p>{`Les parents sont 100% disponibles pour leur enfant, ce qui lui permet d’explorer le milieu en toute confiance.`}</p>
    <p>{`Ainsi, le matériel de support proposé, frites et autres tapis pourra être tenu et relâché par votre enfant mais sans risque puisque, vous serez toujours à ses côtés pour le rattraper. De même les différentes entrées dans l’eau pourront être effectuées avec ou sans matériel de soutien et avec ou sans immersion.`}</p>
    <p>{`La découverte de ce nouveau milieu dépend du vécu antérieur de l'enfant, de ses premiers contacts avec l'eau, de sa familiarisation ou non avec les lieux collectifs.`}</p>
    <p>{`Elle dépend aussi de l'aisance des parents dans l'eau, ainsi que de leurs attentes.`}</p>
    <h3>{`Une attitude d'écoute et non une optique d'apprentissage`}</h3>
    <p>{`Le principe de l'activité est d'observer, d’être à l'écoute de l'enfant afin de pouvoir lui proposer des situations de découvertes variées, sans dissocier l'action elle-même du désir et du plaisir de l'action, refusant la répétition de moments de déplaisir.`}</p>
    <p>{`Nous nous attachons à la façon d'accéder à l'autonomie, au respect du rythme propre de chaque enfant et aux étapes révélées nécessaires.`}</p>
    <p>{`Nous nous basons sur le respect de sa spontanéité. Nous essayons de favoriser le goût de l'activité autonome et la capacité à être créatif, en plaçant les enfants dans des conditions telles qu'ils puissent découvrir le plaisir que peut leur apporter leur propre activité.`}</p>
    <p>{`Les accueillants seront présents pour vous assister si vous le souhaitez. Ils vont aideront à rester autant que possible à l’écoute de votre enfant et à aller à son rythme, en évitant notamment toute manipulation telle que l’immersion forcée.`}</p>
    <p>{`Les accueillants garantissent le cadre et le respect des règles de sécurité. Cependant, les parents restent responsables de leurs enfants et doivent veiller à ce qu'ils ne se mettent pas en danger.`}</p>
    <h3>{`Être force de proposition mais point trop directif`}</h3>
    <p>{`La familiarisation du jeune enfant avec l’eau ne se pose pas en termes d’apprentissage technique. Il ne s’agit pas de natation au sens communément admis par l’adulte mais d’acquisition d’une certaine aisance aquatique, qui facilitera très probablement l’apprentissage ultérieur de la natation.`}</p>
    <p>{`Les créneaux rassemblent les enfants par tranches d'âge et le groupe formé a un rôle d'émulation et d'identification. L'activité, lieu de rencontre et de loisirs, contribue à l'inscription de l'enfant et de sa famille dans le tissu social, relationnel et culturel.`}</p>
    <BassinBallon mdxType="BassinBallon" />
    <p>{`Ainsi, nous privilégions aussi l’accueil d’enfants porteurs de handicaps ou présentant certaines difficultés relationnelles. Le contexte de l’eau, milieu à la fois relaxant et stimulant, offre en effet aux parents de nouvelles expériences originales de partage avec leurs enfants. La proximité des autres enfants produit un effet miroir suscitant l’imitation, leur développement psychomoteur n'en est que plus stimulé. Les enfants bénéficient d’un accueil adapté, tant par les interventions des animateurs s’appuyant sur la diversité de leurs formations, que par la possibilité de moduler les inscriptions dans le temps. Ceci offre l'opportunité aux parents de prendre le temps d’inventer de nouvelles façons d’aborder et de contourner le handicap.`}</p>
    <p><strong parentName="p">{`Ce contexte favorise donc pour tous l’ouverture à l’autre et le dépassement de la différence.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      