import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

export default function BassinFace() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "activite.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Img
      fluid={data.file.childImageSharp.fluid}
      alt="Une sceance avec un enfant sur une frite en mousse"
      className="shadow-lg rounded-lg my-4 md:my-6 md:mr-4 w-full md:w-1/2 float-left"
    />
  );
}
